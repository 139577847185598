@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "joystixmonospace";
  src: url("./Fonts/joystixmonospace.ttf");
}
@font-face {
  font-family: "PixelEmulator";
  src: url("./Fonts/PixelEmulator.otf");
}

@font-face {
  font-family: "Tahoma";
  src: url("./Fonts/Tahoma\ Regular\ font.ttf");
  font-weight: 500;
}
@font-face {
  font-family: "Tahoma";
  src: url("./Fonts/TahomaBold.TTF");
  font-weight: 700;
}

@font-face {
  font-family: "Terminal Medium";
  font-style: normal;
  font-weight: normal;
  src: local("Terminal Medium"), url("./Fonts/Terminal.woff") format("woff");
}

body {
  margin: 0;
  padding: 0;
  background-color: #0b022d;
}
* {
  box-sizing: border-box;
}
.active {
  color: #593bb5;
}
.custom-bg-Hero {
  background-image: url("../public/Herobg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.custom-bg-Hero-nanja{
  background-image: url("../public/banner2.png");
}
.roadmap-bg-left {
  background-image: url("../public/RoadmapLeft.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.roadmap-bg-right {
  background-image: url("../public/RoadmapRight.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.NftLaunced-bg {
  background-image: url("../public/LunchBg.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.bg-footer {
  background-image: url("../public/footer.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
}
/* .css-1elwnq4-MuiPaper-root-MuiAccordion-root:last-of-type{
  width: 800px !important;
} */
/* .css-17lmb9v-MuiPaper-root-MuiAccordion-root:last-of-type{
  background-color: #0e0230 !important;
  border: 1px dashed #2cb21d !important;
  padding: 16px 0px
} */
/* .css-1elwnq4-MuiPaper-root-MuiAccordion-root.Mui-expanded:last-of-type{
  background-color: #200f53;
  border: 2px dashed white;
}
.css-ahj2mt-MuiTypography-root{
  font-family:'Terminal Medium' !important;
  line-height: 40px !important;
} */
/* .css-17lmb9v-MuiPaper-root-MuiAccordion-root.Mui-expanded{
  background-color: #200f53 !important;
  border: 2px dashed white !important;
} */
.swiper-pagination-bullet {
  background: white !important;
}
.swiper-pagination-bullet-active {
  background: white !important;
}

.border {
  background-image: repeating-linear-gradient(
      41deg,
      white,
      white 9.1px,
      transparent 10px,
      transparent 19.1px,
      white 20px
    ),
    repeating-linear-gradient(
      131deg,
      white,
      white 9.1px,
      transparent 10px,
      transparent 19.1px,
      white 20px
    ),
    repeating-linear-gradient(
      221deg,
      white,
      white 9.1px,
      transparent 10px,
      transparent 19.1px,
      white 20px
    ),
    repeating-linear-gradient(
      311deg,
      white,
      white 9.1px,
      transparent 10px,
      transparent 19.1px,
      white 20px
    );
  background-size: 3px calc(100% + 26.5px), calc(100% + 26.5px) 3px,
    3px calc(100% + 26.5px), calc(100% + 26.5px) 3px;
  background-position: 0 0, 0 0, 100% 0, 0 100%;
  background-repeat: no-repeat;
  animation: borderAnimation 0.6s infinite linear;
  border-radius: 15px;
}
@keyframes borderAnimation {
  from {
    background-position: 0 0, -26.5px 0, 100% -26.5px, 0 100%;
  }
  to {
    background-position: 0 -26.5px, 0 0, 100% 0, -26.5px 100%;
  }
}
